<template>
  <v-card :loading="isLoading">
    <div class="d-block w-100 m-3 mb-0">
      <rs-return-to />
    </div>
    <v-card-title class="pt-0">
      Gelen Bildirim - Bireysel(SMS - Mobil) Detayı
      <span class="text-primary ms-5"> Okunma Durumu: </span>
      <v-icon v-if="formData.read_at" color="success">mdi-check</v-icon>
      <v-icon v-else color="warning">mdi-close</v-icon>
      <v-col class="text-end">
        <v-btn
          color="pink"
          outlined
          small
          :loading="isLoading"
          @click="checkMarked()"
          :disabled="formData.read_at"
        >
          Okundu Olarak İşaretle
        </v-btn>
      </v-col>
    </v-card-title>
    <v-card-text v-if="formData.notifiable">
      <v-row dense>
        <v-col cols="6">
          <rs-text-field
            label="Oluşturulma Tarihi"
            :value="new Date(formData.created_at).toLocaleDateString()"
            readonly
          />
        </v-col>
        <v-col cols="6">
          <rs-text-field :value="formData.id" label="Bildirim Id" readonly />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <rs-textarea
            label="Gönderilen İçerik"
            :value="formData.data.text"
            readonly
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "UserNotificationDetail",
  props: {
    /**
     * Notification ID
     * @type {Number}
     */
    id: {
      type: Number,
      required: true,
    },
  },
  watch: {
    id(newValue) {
      if (newValue) {
        this.load();
      }
    },
  },
  data() {
    return {
      isLoading: false,
      formData: {},
    };
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => this.load());
    }
  },
  methods: {
    checkMarked() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`users-notifications/mark-as-read`, { ids: [this.id] })
        .then(() => {
          this.$toast.success(this.$t("saved"));
          this.load();
        })
        .catch((error) => this.handleError(error))
        .finally(() => (this.isLoading = false));
    },
    load() {
      if (!this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .get("users-notifications", this.id)
        .then((response) => {
          this.formData = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
